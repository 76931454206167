import * as React from 'react'
import { Helmet } from 'react-helmet'
import Request from '../../components/Login/Request/Request'

const LoginRequest = () => {
  return (
    <>
      <Helmet>
        <title>Login - Emso</title>
      </Helmet>
      <Request />
    </>
  )
}

export default LoginRequest