import React from 'react'
import { Link } from 'gatsby'
import { omit } from 'lodash'
import { Form, Input, notification, Select, Button } from 'antd'
import styled from 'styled-components'
import Col from '../../Col/Col'
import Row from '../../Row/Row'
import auther from '../../../helpers/auth'
import * as inputStyles from '../../Login/Input.module.scss'
import Header from '../../Header/Header'
import useCreateUserRequest from '../../../apollo/mutation/users/useCreateUserRequest'
import { getErrorsAsObject } from '../../../helpers/apollo'
import { STRATEGY_INTEREST_OPTIONS, TYPE_OF_USER_OPTIONS } from '../../Admin/Dashboard/Users/constants'
import FormItem from '../../Admin/ui/FormItem'
import { container, body, box, header, login} from './Request.module.scss'
import { countryList, selectCountryFilter } from 'src/utils/helpers/countries'

const auth = auther()

const { Option } = Select

const StyledFormItem = styled(FormItem)`
  margin-bottom: 10px;
`

export default () => {
  const formRef = React.createRef()
  const [errors, setErrors] = React.useState({})
  const [showConfirmationMessage, setShowConfirmationMessage] = React.useState(false)

  const [createUserRequest, { loading }] = useCreateUserRequest({
    onError: error => {
      setErrors(getErrorsAsObject(error))
      notification.error({ message: 'Something went wrong. Please check the highlighted fields' })
    },
    onCompleted: () => setShowConfirmationMessage(true),
    showErrorNotification: false,
  })
  const auth0Login = event => {
    event.preventDefault()
    auth.login()
  }

  const handleSubmit = async params => {
    createUserRequest({ variables: { params } })
  }

  const handleFormChange = changedValues => {
    setErrors(omit(errors, Object.keys(changedValues)))
  }
  return (
    <div className={body}>
      <Header showNavigation={false} hideLogoText={false} menuOpen={false}/>
      <div className={box}>
        <Col>
          <Row>
            <Form ref={formRef} className={container} onFinish={handleSubmit} onValuesChange={handleFormChange}>
              {showConfirmationMessage && (
                <>
                  <h1 className={header}>Request sent</h1>
                  <p>Your request will be processed shortly.</p>
                </>
              )}
              {!showConfirmationMessage && (
                <>
                  <div>
                    <h1 className={header}>Request Access</h1>
                  </div>
                  <StyledFormItem error={errors.givenName} name="givenName">
                    <Input size="small" placeholder="First Name" />
                  </StyledFormItem>
                  <StyledFormItem error={errors.familyName} name="familyName">
                    <Input size="small" placeholder="Last Name" />
                  </StyledFormItem>
                  <StyledFormItem error={errors.email} name="email">
                    <Input type="email" size="small" placeholder="Email Address" />
                  </StyledFormItem>
                  <StyledFormItem error={errors.phoneNumber} name="phoneNumber">
                    <Input size='small' placeholder="Phone Number (Country Code + Number)" />
                  </StyledFormItem>
                  <StyledFormItem error={errors.company} name="company">
                    <Input size="small" placeholder="Company" />
                  </StyledFormItem>
                  <StyledFormItem error={errors.street} name="street">
                    <Input size="small" placeholder="Street" />
                  </StyledFormItem>
                  <StyledFormItem error={errors.city} name="city">
                    <Input size="small" placeholder="City" />
                  </StyledFormItem>
                  <StyledFormItem error={errors.country} name="country">
                    <Select showSearch name="country" placeholder="Country" filterOption={selectCountryFilter}>
                      {countryList.map(({ name, code }) => (
                        <Option value={name} key={code}>{name}</Option>
                      ))}
                    </Select>
                  </StyledFormItem>
                  <StyledFormItem error={errors.strategyInterest} name="strategyInterest">
                    <Select showSearch placeholder="Strategy Interest">
                      {STRATEGY_INTEREST_OPTIONS.map(item => <Option value={item} key={item}>{item}</Option>)}
                    </Select>
                  </StyledFormItem>
                  <StyledFormItem error={errors.typeOfUser} name="typeOfUser">
                    <Select showSearch placeholder="Type of User">
                      {TYPE_OF_USER_OPTIONS.map(item => <Option value={item} key={item}>{item}</Option>)}
                    </Select>
                  </StyledFormItem>
                  <div>
                    <hr className={inputStyles.hr}/>
                  </div>
                  <Row inlineRow>
                    <Col noPadding>
                      <Link to='/login' className={login} onClick={auth0Login}>
                        Login
                      </Link>
                    </Col>
                    <Col noPadding>
                      <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </Row>
        </Col>
      </div>
    </div>
  )
}
