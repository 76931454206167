import * as React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import * as layoutStyles from '../../layouts/Layout.module.scss'
import * as styles from './Col.module.scss'

const classMap = ['small', 'medium', 'large', 'xlarge']

const Col = ({ children, className, noPadding, ...otherProps }) => {
  const classes = Object.keys(otherProps)
    .filter(key => classMap.includes(key))
    .map(key => styles[key + otherProps[key]])

  return (
    <div
      className={classnames(classes, {
        [className]: className,
        [layoutStyles.column]: !noPadding,
      })}
    >
      {children}
    </div>
  )
}

Col.propTypes = {
  ...{
    ...classMap.reduce((obj, className) => ({
      ...obj,
      [className]: PropTypes.number,
    }), {}),
  },
  children: PropTypes.node,
}

export default Col
