import gql from 'graphql-tag'
import useMutation from '../useMutation'
import { userFieldsFragment } from '../../fragments/user'

export const updateUserMutation = gql`
    mutation CreateUserRequest($params: CreateUserInput!) {
        createUserRequest(params: $params){
            ...userFields
        }
    }
    ${userFieldsFragment}
`

const useCreateUserRequest = ({ params, ...options } = {}) => {
  return useMutation(updateUserMutation, {
    variables: { params }, ...options,
  })
}

export default useCreateUserRequest
